<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t('message.add_new_service') }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
              <el-button @click="close()">{{ $t('message.close') }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="services_block">
    <el-row class="p20">
      <el-col :xs="24" :sm="15" :md="15" :lg="15" :xl="15" v-loading="loadingData">
          <el-select v-model="patientServiceId" filterable :placeholder="$t('message.start_input_find_service')" class="autoInput" clearable>
              <template v-for="serviceParent in servicesSelected">
                  <el-option-group
                    v-if="serviceParent.children.length > 0"
                    :key="serviceParent.id"
                    :label="serviceParent.name">
                        <el-option
                            v-for="service in getChildrens(serviceParent.children)"
                            :key="service.id"
                            :label="service.name"
                            :value="service.id">
                        </el-option>
                  </el-option-group>
                  <el-option-group 
                    v-else-if="serviceParent.is_service == true && serviceParent.parent_id == null"
                    :key="serviceParent.id">
                        <el-option
                            :label="serviceParent.name"
                            :value="serviceParent.id">
                        </el-option>
                  </el-option-group>
              </template>
          </el-select>
      </el-col>
      <!-- end col -->

      <el-col :xs="24" :sm="9" :md="9" :lg="9" :xl="9">
          <el-button style="font-size:14px!important;padding:12px!important;" @click="addService">{{ $t('message.add') }}</el-button>
          <el-button style="font-size:14px!important;padding:12px!important;" @click="drawerPatientService = true">{{ $t('message.add_service') }}</el-button>
      </el-col>
      <!-- end col -->

      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <table class="table table-striped table-bordered mt-4">
              <thead>
              <tr>
                  <th>{{ $t('message.pp') }}</th>
                  <th class="wi-50">{{ $t('message.name') }}</th>
                  <th class="wi-7">{{ $t('message.quantity_short') }}</th>
                  <th class="wi-7"> {{ $t('message.price2') }}</th>
                  <th class="wi-7">{{ $t('message.discount_percent') }}</th>
                  <th class="wi-7">{{ $t('message.discount_price') }}</th>
                  <th class="wi-7">{{ $t('message.amount') }}</th>
                  <th class="wi-7">{{ $t('message.delete') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(ser,index) in list" :key="'order-create-in-header'+index"> 
                      <td>{{ index + 1 }}</td>
                      <td>
                          {{ ser.name }}
                      </td>
                      <td>
                          <el-input type="number"  id="usr"  step="any" @input="editRow(ser,$event,'count')" :value="ser.count"  :min="1"></el-input>
                      </td>
                      <td>
                          {{ ser.price | formatMoney }}
                      </td>
                      <td>
                          <el-input-number type="number" size="small" @change="editRow(ser,$event,'discount_price')" controls-position="right" :value="ser.discount_price" :min="0" :max="100"></el-input-number>
                      </td>
                      <td>
                          {{ Math.round(ser.discount_price * ser.price/100) | formatMoney }}
                      </td>
                      <td>
                          {{ Math.round(ser.count * (ser.price - ((ser.price * ser.discount_price) / 100))  ) | formatMoney }}
                      </td>
                      <td>
                          <el-button class="d-block"
                          type="danger" icon="el-icon-delete" circle
                          @click.native.prevent="deleteRow(ser)">
                          </el-button>
                      </td>
                  </tr>
              </tbody>
              <tfoot>
                  <th colspan="5">{{ $t('message.total_0') }}</th>
                  <th class="w-7">{{ totalDiscount | formatMoney }}</th>
                  <th colspan="2"> {{ Math.round(totalAmount) | formatMoney  }}</th>
              </tfoot>
          </table>
      </el-col>
    </el-row>
    </div>
    <!-- <el-drawer :visible.sync="drawer" size="70%" :append-to-body="true" :with-header="false">
      <AddPatientService2 />
    </el-drawer> -->
    <el-drawer class="popups_title" :wrapperClosable="false"  :append-to-body="true" :visible.sync="drawerPatientService"  direction="rtl" size="70%">
        <CreatePatientService :driver="drawerPatientService" @c-close="closeDrawerPatientService"></CreatePatientService>
    </el-drawer>
  </div>
</template>
<script>
import AddPatientService2 from "./addPatientService2";
import { mapGetters, mapActions } from "vuex";
import order from '@/utils/mixins/order';
import form from '@/utils/mixins/form';
import drawerChild from '@/utils/mixins/drawer-child';

export default {
    mixins: [order, drawerChild],
    props: ['currentPatient'],
    data() {
        return {
            reopenUpdate: false,
            loadingButton:false,
            loadingData: false,
        };
    },

    computed:{
        ...mapGetters({
            rules: 'hospitalizationServices/rules',
            model: 'hospitalizationServices/model',
            columns: 'hospitalizationServices/columns',
            lastHistory: 'dailyTreatments/lastHistory',
        })
    },
    methods: {
        ...mapActions({
            editPatient: 'patients/show',
            editService: 'services/show',
            save: 'hospitalizationServices/store',
            update: 'hospitalizationServices/update',
            saveList: 'services/setlist',
        }),
        afterOpened(){
            this.form = JSON.parse( JSON.stringify(this.model) );
        },
        afterClosed(){
            this.empty()
        },
        setNewOrderData(){
            this.form = JSON.parse( JSON.stringify( this.model ));
        },
        submit(close = true){
            this.loadingButton = true;
            this.form.services = this.list;
            this.form.patient_history_id = this.lastHistory.id;
            this.save(this.form)
                .then(res => {
                    this.$alert(res);
                    this.parent().listChanged()
                    if (close == true) {
                        this.close();
                    }
                    this.loadingButton = false;
                })
                .catch(err => {
                    this.loadingButton = false;
                    this.ifValidationError(err);
                });
        },
        closeForm(){
            this.old_order = {};
            this.old_save = false;
            this.empty()
            this.$emit('c-close',{reload: false, drawer: this.drawer});
        },
        resetForm(){
            this.setNewOrderData();
            this.user = {};
            this.noUser();
            this.emptySearch();
            this.emptyList();
            this.search = '';
            this.latestServices = [];
        },
        empty(){
            this.activeTab = 'first';
            this.emptyList();
            this.emptySearch();
            this.noUser();
            this.user = null;
            this.patientServiceId = '';
            this.search = '';
            this.latestServices = [];
        }
    }
}
</script>
<style lang="scss" >
.services_block {
  padding: 20px;
}
.select_grupp {
  display: flex;
  .el-select {
    width: 600px;
    margin-right: 20px;
  }
}
.topSarche {
  display: flex;
  justify-content: space-between;
}
.wt5 {
  width: 4%;
}
.wt10 {
  width: 12%;
}
</style>