<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">Услуги</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button type="success">Сохранить и закрыть</el-button>
              <el-button>Закрыть</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="secise_cheboxs">
      <div class="block">
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node secise_cheboxs_summ" slot-scope="{ node, }">
            <span>{{ node.label }}</span>
            <span>
              <span class="chebox_sum">35 000.00 сум</span>
            </span>
          </span>
        </el-tree>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 1,
      value: "",
      data: [
        {
          id: 1,
          label: "Level one 1",
          children: [
            {
              id: 3,
              label: "Level two 2-1",
              children: [
                {
                  id: 4,
                  label: "Level three 3-1-1"
                },
                {
                  id: 5,
                  label: "Level three 3-1-2",
                  disabled: true
                }
              ]
            },
            {
              id: 2,
              label: "Level two 2-2",
              disabled: true,
              children: [
                {
                  id: 6,
                  label: "Level three 3-2-1"
                },
                {
                  id: 7,
                  label: "Level three 3-2-2",
                  disabled: true
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: "children",
        label: "label",
        disabled: "disabled"
      }
    };
  }
};
</script>
<style lang="scss">
.secise_cheboxs {
  padding: 20px;
}
.secise_cheboxs_summ {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 93%;
}
</style>